// KycFetcher.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'oidc-react';
import {compressString} from '../utilities/compressToken';

const KycFetcher = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const authToken = compressString(auth.userData.access_token);
  const merchant = useSelector((state) => state.merchant);

  useEffect(() => {
    if (!merchant) {
      console.log("Merchant not available yet");
      return;
    }

    const fetchKycList = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/kycs`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
              'X-Zitadel-OrgID': merchant.zitadelId,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const kycList = await response.json();
        dispatch({ type: 'SET_KYC_LIST', kycList });

        // if (kycList?.length > 0) {
        //   dispatch({ type: 'SET_SELECTED_KYC', selectedKyc: kycList[0] });
        // }
      } catch (error) {
        console.error('Failed to fetch KYC list:', error);
      }
    };

    fetchKycList();
  }, [dispatch, auth, merchant]);

  return null;
};

export default KycFetcher;
