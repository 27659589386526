import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider, useAuth } from "oidc-react";
import i18n from "i18next";
import './scss/style.scss'
import { CSpinner } from '@coreui/react';
import { useDispatch } from 'react-redux';
import KycFetcher from './components/KycFetcher';

const loading = (
	<div className="pt-3 text-center">
		<CSpinner />
	</div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function BuckAuth(props) {
	let auth = useAuth();
	if (!auth.userData) return loading;

	return props.children;
}

const App = () => {
	const dispatch = useDispatch();

	const oidcConfig = {
		onSignIn: async (response) => {
			// reset current merchant on login
			dispatch({ type: 'set', merchant: null });
			
			const locale = response.profile.locale;
			if (locale) {
				localStorage.setItem("LNG", locale);
				i18n.changeLanguage(locale);
			}
			
			history.replaceState(null, "", "/");
		},
		authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
		clientId: process.env.REACT_APP_ZITADEL_CLIENT_ID,
		responseType: "code",
		redirectUri: process.env.REACT_APP_URL,
		postLogoutRedirectUri: process.env.REACT_APP_URL,
		scope: "openid profile email",
	};

	return (
		<AuthProvider {...oidcConfig}>
			<BuckAuth>
			<KycFetcher />
				<Suspense fallback={loading}>
					<HashRouter>
						<Routes>
							<Route exact path="/404" name="Page 404" element={<Page404 />} />
							<Route exact path="/500" name="Page 500" element={<Page500 />} />
							<Route path="*" name="Home" element={<DefaultLayout />} />
						</Routes>
					</HashRouter>
				</Suspense>
			</BuckAuth>
		</AuthProvider>
	)
}

export default App