import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const initialState = {
  sidebarShow: true,
  merchant: null,
  kycList: [],
  selectedKyc: null,
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'SET_KYC_LIST':
      return { ...state, kycList: rest.kycList || [] };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, changeState);

// const store = createStore(changeState)
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);