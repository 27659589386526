import { gzipSync, strToU8 } from "fflate";

export function compressString(data: string | undefined) {
    if(data === undefined)
        return undefined;

    const inputData = strToU8(data);
    const compressedData = gzipSync(inputData);

    let binaryString = '';
    compressedData.forEach(byte => {
        binaryString += String.fromCharCode(byte);
    });

    return btoa(binaryString);
}